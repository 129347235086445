<template>
    <div class="content pt-0">
        <section id="section-booking" class="section booking customCheckout">
            <div class="container">
                <div class="row align-items-center" style="color: white">
                    <div class="col-md-6 text-center py-4">
                        <h2>Bejelentkezés</h2>
                        <p>
                            Ha rendelkezel felhasználói fiókkal, kérlek
                            jelentkezz be.
                        </p>
                        <router-link
                            :to="{ name: 'Login' }"
                            class="bt-orange add-to-cart-btn"
                            >Bejelentkezés</router-link
                        >
                    </div>
                    <div class="col-md-6 py-4">
                        <h2 class="text-center">
                            Regisztráció
                        </h2>
                        <p class="text-center">
                            Ha még nincs fiókod érdemes regisztrálni, mert a
                            későbbiekben sokkal gyorsabban fogsz tudni
                            vásárolni.
                        </p>
                        <form v-on:submit.prevent="doRegistration">
                            <div class="my-4">
                                <label>Teljes név</label>
                                <input
                                    type="text"
                                    name="name"
                                    v-model="form.name"
                                    class="form-control form-underlined"
                                />
                            </div>
                            <div class="my-4">
                                <label>Telefonszám</label>
                                <input
                                    type="text"
                                    name="phone"
                                    v-model="form.phone"
                                    class="form-control form-underlined"
                                />
                            </div>
                            <div class="my-4">
                                <label>Email cím</label>
                                <input
                                    type="email"
                                    name="email"
                                    v-model="form.email"
                                    class="form-control form-underlined"
                                />
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label>Jelszó</label>
                                    <input
                                        type="password"
                                        name="password"
                                        v-model="form.password"
                                        class="form-control form-underlined"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label>Jelszó újra</label>
                                    <input
                                        type="password"
                                        name="password_again"
                                        v-model="form.password_confirmation"
                                        class="form-control form-underlined"
                                    />
                                </div>
                            </div>
                            <label class="d-block my-4">
                                <input
                                    type="checkbox"
                                    name="aszf"
                                    value="1"
                                    v-model="form.aszf"
                                />
                                Elfogadom az
                                <router-link :to="'/adatkezeles'"
                                    >Adatvédelmi nyilatkozatot</router-link
                                >
                            </label>
                            <button
                                type="submit"
                                class="bt-orange add-to-cart-btn w-100"
                            >
                                Regisztráció
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "Registration",
    data() {
        return {
            form: {
                email: null,
                password: null,
                name: null,
                password_confirmation: null,
                aszf: false,
                phone: null
            }
        };
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_URL;
        }
    },
    methods: {
        doRegistration() {
            this.$http
                .post(`${this.url}/register`, this.form)
                .then(response => {
                    $cookies.set("id_token", response.data.token, 86400);
                    document.location.href = "/";
                });
        }
    }
};
</script>

<style scoped></style>
